import React from 'react';
import { Link } from 'gatsby';
import App from "../../components/App"

export default function Research() {
	return (
		<App>
		<div className='right-frame'>
			<div className='wrapper'>
      			<ResearchCard
        			img='/honeybee.jpg'
        			title='Demystifying Honey'
        			description='Details on honey from modern medical journals & ancient indian texts'
        			label='Research'
        			blog_path='/Honey.pdf'
      			/>
      		</div>
		</div>
		</App>
	)
}

function ResearchCard(props) {
  return (
    <>
      	<div className='card'>
      		<figure className='card_pic-wrap' data-category={props.label}>
        		<img src={props.img} className='card_img'></img>
        	</figure>
        	<div className='card__body'>
          		<h2 className='card__title'>{props.title}</h2>
          		<p className='card__description'>{props.description}</p>
     			<button className='card__btn'>
              		{/*<Link className='btn_link' to={props.blog_path}>
                		PDF
              		</Link>*/}
              		<a href={props.blog_path} download className='btn_link'>PDF</a>
            	</button>
            </div>
        </div>
    </>
  );
}
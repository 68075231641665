import React from 'react'
import { Link } from 'gatsby';
import './Footer.css';

function Footer() {
	return (
		<div className='footer-container'>
			<h4>DISCLAIMER</h4>
			<p>
				We don’t provide any medical advice, diagnosis, or treatment. Our website services and contents are for informational purposes only. Please consult your doctor or health care provider for treatment of any medical condition. &nbsp;

				<Link to='/disclaimer' className='disclaimer_link'>Please click here to see additional information and disclaimer</Link>. 
				
				All rights reserved.
			</p>
    	</div>
	);
}

export default Footer


export const SidebarData = [
	{
		title: 'Home',
		path: '/',
		cName: 'side-text',
	},
	{
		title: 'Contributors',
		path: '/contributors',
		cName: 'side-text',
	},
	{
		title: 'Research',
		path: '/research',
		cName: 'side-text',
	},
	{
		title: 'About',
		path: '/about',
		cName: 'side-text',
	},
]
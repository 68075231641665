import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
/*import ReactGa from 'react-ga';*/
import { SidebarData } from './SidebarData';
import Footer from './Footer';
import './App.css';
import './Sidebar.css';
//import getFirebase from './Database.js'

function App({ children }) {

  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);

  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    /*ReactGa.initialize('UA-210374334-1');
    ReactGa.pageview(window.location.pathname + window.location.search);*/

    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);

    /*const firebase = getFirebase();*/

  }, []);

  const updateScreenSize = () => {
    if(window.innerWidth <= 960) {
      setSmallScreen(true);
      setSidebar(false);
    } else {
      setSmallScreen(false);
      setSidebar(true);
    }
  }

  return (
    <>
      <div className='header_container'>
        <Link to="#" className='menu-bars'>
          <img className='menu_icon' src='/menu.jpg' alt="Menu" onClick={showSidebar}/>
        </Link>
          
        <Link to='/'><img className='logo' src='/codreams_logo.png' alt="CoDreams"/></Link>
          &nbsp; &nbsp;
        <h2 className='site_heading'>Health</h2>
      </div>

      <div className={(!smallScreen && sidebar) ? 'body_container' : 'body_container active'}>
        <div className={(sidebar) ? 'w_column1' : 'w_column1 active'}>
          <div className={sidebar ? 'side-menu active' : 'side-menu'}>
            <ul>
            {/*<ul className='side-menu-items' onClick={smallScreen ? showSidebar : 0}>*/}
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      <span className='menu_item'>{item.title}</span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        
        <div className={(sidebar) ? 'w_column2' : 'w_column2 active'}>
          <ScrollToTop />
          { children }
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}